<template>
	<v-card>
		<v-snackbar color="primary" v-model="snackbar.status" :timeout="snackbar.timeout" dark>
			<span class="font-weight-bold">{{snackbar.text}}</span>
			<template v-slot:action="{ attrs }">
				<v-btn
					class="font-weight-bold"
					color="blue-grey lighten-3"
					text
					v-bind="attrs"
					@click="snackbar.status = false"
				>Fechar</v-btn>
			</template>
		</v-snackbar>
		<v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
			<v-card color="primary" dark>
				<v-card-text class="pt-3">
					Aguarde enquanto o contrato é enviado!
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-dialog v-model="dialog" max-width="500">
			<v-card>
				<v-card-title>Envio de contrato assinado</v-card-title>
				<v-divider />
				<v-card-text v-if="dadosContrato.existeContrato" class="pt-5">
					<p
						class="font-weight-bold"
					>Cliente: {{dadosContrato.nome}} (Pedido: {{dadosContrato.idcontrato}})</p>
					<v-file-input
						show-size
						counter
						:loading="carregando"
						label="Contrato"
						accept="image/png, image/jpeg, image/jpg"
						@change="adicionarArquivo"
					></v-file-input>
					<p class="mt-5 mb-0">* Tamanho máximo de 5mb por arquivo.</p>
					<p
						class="mb-0 red--text"
					>** Antes de enviar o arquivo, confira se a imagem ou PDF estão legíveis e se todos os campos foram preenchidos corretamente.</p>
				</v-card-text>
				<v-card-text v-else class="pt-8">
					<v-alert
						type="error"
					>Pedido não teve contrato gerado no CISS. Para gerar contrato deve ser gerado no relatório 150389.</v-alert>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn
						:loading="carregando"
						text
						color="primary"
						elevation="0"
						@click="dialog = false"
					>Cancelar</v-btn>
					<v-btn
						:loading="carregando"
						v-if="dadosContrato.existeContrato"
						color="primary"
						elevation="0"
						@click="enviarContrato()"
					>Enviar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogCloud" max-width="575" scrollable>
			<v-card>
				<v-card-title>Solicitar contrato online</v-card-title>
				<v-divider />
				<v-card-text class="black--text pt-5">
					<v-row no-gutters>
						<v-col cols="12">
							<p class="text-h6">Deseja realmente solicitar um link de contrato online?</p>
						</v-col>
						<v-col cols="12">
							<p class="mb-0">
								<strong>Cliente:</strong>
								{{dadosContrato.nome}}
							</p>
						</v-col>
						<v-col cols="12">
							<p>
								<strong>Pedido:</strong>
								{{dadosContrato.idcontrato}}
							</p>
						</v-col>
						<v-col cols="12" v-if="retornoOnline">
							<v-text-field
								ref="copiar"
								class="mb-n5"
								dense
								v-model="retornoOnline"
								outlined
								append-icon="mdi-content-copy"
								@click:append="copy()"
							/>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn
						:loading="carregando"
						text
						color="primary"
						elevation="0"
						@click="dialogCloud = false"
					>Cancelar</v-btn>
					<v-btn
						:disabled="retornoOnline ? true : false"
						:loading="carregando"
						color="primary"
						elevation="0"
						@click="solicitarContratoOnline()"
					>Solicitar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogEncomenda" max-width="575" scrollable>
			<v-card>
				<v-card-title>Liberar pedido para encomenda</v-card-title>
				<v-divider />
				<v-card-text class="black--text pt-5">
					<v-row no-gutters>
						<v-col cols="12">
							<p class="text-h6">Deseja realmente liberar este pedido como uma encomenda?</p>
						</v-col>
						<v-col cols="12">
							<p
								class="mb-0"
							>Pedidos liberados para encomenda serão enviados para o setor de compras, aonde será realizado uma análise de reposição de mercadoria na filial.</p>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn
						:loading="carregando"
						text
						color="primary"
						elevation="0"
						@click="dialogEncomenda = false"
					>Cancelar</v-btn>
					<v-btn :loading="carregando" color="primary" elevation="0" @click="contratoAutorizar()">Liberar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-card-title class="title">
			<v-row no-gutters>
				<v-col class="d-none d-md-block pr-1">
					<InputDatePicker
						:disabled="carregando"
						v-model="busca.dtinicio"
						label="Data inicial"
						:outlined="true"
						:dense="true"
					/>
				</v-col>
				<v-col class="d-none d-md-block px-1">
					<InputDatePicker
						:disabled="carregando"
						v-model="busca.dtfim"
						label="Data final"
						:outlined="true"
						:dense="true"
					/>
				</v-col>
				<v-col class="d-none d-md-block px-1">
					<v-autocomplete
						:items="filiaisFiltro"
						clearable
						item-text="filial"
						item-value="idfilial"
						label="Filial"
						outlined
						dense
						:disabled="carregando"
						v-model="busca.idfilial"
					></v-autocomplete>
				</v-col>
				<v-col class="d-none d-md-block px-1">
					<v-select
						clearable
						:items="status.lista"
						item-text="status"
						item-value="idstatus"
						label="Status"
						outlined
						dense
						:disabled="carregando"
						v-model="busca.idstatus"
					></v-select>
				</v-col>
				<v-col class="d-none d-md-block px-1">
					<v-select
						clearable
						:items="[{idsituacao: 1, situacao: 'Produtos pendentes'},{idsituacao: 2, situacao: 'Produtos entregues'}]"
						item-text="situacao"
						item-value="idsituacao"
						label="Situação"
						outlined
						dense
						:disabled="carregando"
						v-model="busca.idsituacao"
					></v-select>
				</v-col>
				<v-bottom-sheet class="d-flex d-md-none" v-model="sheet">
					<v-sheet class="text-center" height="100%">
						<v-row class="pa-3">
							<v-col cols="6" class="mb-n3 px-2">
								<InputDatePicker
									:disabled="carregando"
									v-model="busca.dtinicio"
									label="Data inicial"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-col cols="6" class="mb-n3 px-2">
								<InputDatePicker
									:disabled="carregando"
									v-model="busca.dtfim"
									label="Data final"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-col cols="12" class="mb-n8 px-2">
								<v-autocomplete
									:items="filiaisFiltro"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfilial"
								></v-autocomplete>
							</v-col>
							<v-col cols="6" class="mb-n8 px-2">
								<v-select
									clearable
									:items="status.lista"
									item-text="status"
									item-value="idstatus"
									label="Status"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idstatus"
								></v-select>
							</v-col>
							<v-col cols="6" class="mb-n8 px-2">
								<v-select
									clearable
									:items="[{idsituacao: 1, situacao: 'Produtos pendetes'},{idsituacao: 2, situacao: 'Produtos entregues'}]"
									item-text="situacao"
									item-value="idsituacao"
									label="Situação"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idsituacao"
								></v-select>
							</v-col>
							<v-col cols="6">
								<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
							</v-col>
							<v-col cols="6">
								<v-btn text class="mt-n3" color="primary" @click="listar(0), sheet = !sheet">Filtrar</v-btn>
							</v-col>
						</v-row>
					</v-sheet>
				</v-bottom-sheet>
				<v-col class="pl-1">
					<v-text-field
						class="d-flex d-md-none"
						:disabled="carregando"
						v-model="busca.busca"
						label="Buscar"
						outlined
						dense
						@keydown.enter="listar(0)"
						append-outer-icon="mdi-menu"
						@click:append-outer="sheet = !sheet"
					/>
					<v-text-field
						class="d-none d-md-block"
						:disabled="carregando"
						v-model="busca.busca"
						label="Buscar"
						outlined
						dense
						@keydown.enter="listar(0)"
					/>
				</v-col>
				<v-col cols="auto" class="ml-2">
					<v-btn
						:disabled="carregando"
						class="mt-1"
						color="primary"
						elevation="0"
						fab
						x-small
						@click="listar(0)"
					>
						<v-icon>mdi-magnify</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-title>
		<v-divider />
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-card dark flat class="rounded-0 mt-3" color="primary">
			<v-row>
				<v-col cols="12" md="4" class="text-center font-weight-bold">
					Total de contratos
					<v-chip color="white" class="primary--text ml-2">
						<template v-if="totais.total && !carregandoTotais">{{totais.total}}</template>
						<template v-else>
							<v-progress-circular indeterminate size="18" color="primary"></v-progress-circular>
						</template>
					</v-chip>
				</v-col>
				<v-col cols="12" md="4" class="text-center font-weight-bold">
					Contratos enviados
					<v-chip color="white" class="primary--text ml-2">
						<template v-if="!carregandoTotais">{{totais.enviado}}</template>
						<template v-else>
							<v-progress-circular indeterminate size="18" color="primary"></v-progress-circular>
						</template>
					</v-chip>
				</v-col>
				<v-col cols="12" md="4" class="text-center font-weight-bold">
					Enviados (%)
					<v-chip color="white" class="primary--text ml-2">
						<template
							v-if="totais.total && !carregandoTotais"
						>{{(totais.enviado / totais.total * 100).toFixed(2)}} %</template>
						<template v-else>
							<v-progress-circular indeterminate size="18" color="primary"></v-progress-circular>
						</template>
					</v-chip>
				</v-col>
				<small class="ml-auto mr-6 mt-n2 text-caption grey--text">
					* Última atualização:
					<span v-if="totais.dataatualizacao">{{totais.dataatualizacao}}</span>
					<v-progress-circular class="pl-10" v-else indeterminate size="18" color="grey"></v-progress-circular>
				</small>
			</v-row>
		</v-card>
		<v-card-text>
			<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
			<v-simple-table v-else>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">ID</th>
							<th class="text-left">Cliente</th>
							<th class="text-left">Filial</th>
							<th class="text-left">Data pedido</th>
							<th class="text-left">Status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<template v-if="dados.total > 0">
							<tr v-for="(u, i) in dados.lista" :key="i">
								<td v-if="permissao('contrato_get')">
									<v-btn
										small
										color="primary"
										outlined
										block
										:to="`/contrato/get/${u.idcontrato}`"
									>{{u.idcontrato}}</v-btn>
								</td>
								<td v-else>{{u.idcontrato}}</td>
								<td>{{u.nome}}</td>
								<td>{{u.filial}}</td>
								<td>{{u.dtcontrato | formataData}}</td>
								<td v-if="u.status" class="text-truncate">
									<v-icon class="mr-3" color="success">mdi-check</v-icon>Contrato enviado
								</td>
								<td v-else-if="u.encomenda" class="text-truncate">
									<v-icon class="mr-3" color="orange">mdi-check</v-icon>Liberado encomenda
								</td>
								<td v-else class="text-truncate">
									<v-icon class="mr-3" color="red">mdi-alert-circle-outline</v-icon>Contrato pendente
								</td>
								<td class="text-truncate">
									<v-btn class="mr-1" elevation="0" color="primary" small @click="contratoValida(u)">
										<v-icon>mdi-upload</v-icon>
									</v-btn>
									<v-btn elevation="0" color="primary" small @click="dialogContratoOnline(u)">
										<v-icon>mdi-cloud-upload-outline</v-icon>
									</v-btn>
									<v-btn
										class="ml-1"
										v-if="permissao('contrato_libera_encomenda')"
										elevation="0"
										color="primary"
										small
										@click="dialogLiberarEncomenda(u)"
									>ENC</v-btn>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="6">Nenhum registro encontrado.</td>
							</tr>
						</template>
					</tbody>
				</template>
			</v-simple-table>
			<Paginacao
				:total="dados.total"
				@atualizar="listar"
				:carregando="carregando"
				:pg="pgSelecionada"
			/>
		</v-card-text>
		<v-progress-linear indeterminate absolute :active="carregando" />
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import Paginacao from "../Widgets/Paginacao";
import CardDialog from "../Widgets/CardDialog";
import InputDatePicker from "../Widgets/InputDatePicker";

export default {
	name: "ContratoLista",
	mixins: [mixinFilial],
	components: { Paginacao, CardDialog, InputDatePicker },
	data: () => ({
		snackbar: { status: false, timeout: 1500 },
		dialog: false,
		dialogLoading: false,
		dialogCloud: false,
		dialogEncomenda: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		sheet: false,
		carregando: false,
		carregandoSkeleton: true,
		carregandoTotais: true,
		dados: {},
		totais: {},
		dadosContrato: { existeContrato: false },
		retornoOnline: "",
		arquivos: [],
		status: {},
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
			idstatus: null,
			idsituacao: null,
		},
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		listar(pg) {
			this.pgSelecionada = pg;
			let status = null;
			let encomenda = null;
			let situacao = null;
			if (this.busca.idstatus == 1) {
				status = false;
				encomenda = false;
			}
			if (this.busca.idstatus == 2) {
				status = true;
				encomenda = false;
			}
			if (this.busca.idstatus == 3) {
				encomenda = true;
				status = false;
			}
			if (this.busca.idsituacao == 1) {
				situacao = true;
			}
			if (this.busca.idsituacao == 2) {
				situacao = false;
			}
			this.carregando = true;
			this.carregandoTotais = true;
			return axios
				.post(`${this.backendUrl}contrato/listar`, {
					limit: this.pgLimit,
					offset: this.pgLimit * pg,
					busca: this.busca.busca || null,
					dtini: this.busca.dtinicio || null,
					dtfim: this.busca.dtfim || null,
					idfilial: this.busca.idfilial || null,
					status: status,
					encomenda: encomenda,
					situacao: situacao,
				})
				.then((res) => {
					localStorage.setItem(
						"filtros_contratos",
						JSON.stringify(this.busca)
					);
					this.dados = res.data;
					this.carregando = false;
					this.carregandoSkeleton = false;
					this.listarTotais();
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		listarTotais() {
			return axios
				.post(`${this.backendUrl}contrato/totais`, {
					dtini: this.busca.dtinicio || null,
					dtfim: this.busca.dtfim || null,
					idfilial: this.busca.idfilial || null,
				})
				.then((res) => {
					this.totais = res.data;
					this.carregandoTotais = false;
				});
		},
		listarStatus() {
			return axios
				.post(`${this.backendUrl}contrato/status/listar`, {
					limit: 99999,
					offset: 0,
				})
				.then((res) => {
					this.status = res.data;
				});
		},
		dialogContratoOnline(contrato) {
			this.retornoOnline = "";
			this.dadosContrato = { existeContrato: false };
			this.dadosContrato = contrato;
			this.dadosContrato.existeContrato = true;
			this.dialogCloud = true;
		},
		solicitarContratoOnline() {
			this.dialogLoading = true;
			return axios
				.post(`${this.backendUrl}contrato/online`, {
					idcontrato: parseInt(this.dadosContrato.idcontrato),
				})
				.then((res) => {
					this.dialogLoading = false;
					if (res.data) {
						this.retornoOnline = res.data.url;
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg =
								"Não foi possível realizar o envio do contrato!";
						} else {
							this.dialogErro.msg = res.data.erro;
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.dialogLoading = false;
					this.dialogErro.title = "Erro";
					if (
						e.response.data.erro ==
						"pedido aguardando liberação da analise de credito"
					) {
						this.dialogErro.msg =
							"Pedido em análise de crédito! Aguarde a liberação.";
					} else {
						this.dialogErro.msg =
							"Não foi possível realizar o envio do contrato!";
					}
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		copy() {
			var copyTextarea = this.$refs.copiar.$refs.input;
			copyTextarea.select();
			try {
				var successful = document.execCommand("copy");
				var msg = successful ? "sim!" : "não!";
				if (successful) {
					msg = `"${this.retornoOnline}" foi copiado para sua área de transferência`;
				} else {
					msg =
						"Não foi possível copiar o texto, use o atalho Crtl+C.";
				}
				this.snackbar.text = msg;
				this.snackbar.status = true;
			} catch (err) {
				this.snackbar.text =
					"Não foi possível copiar o texto, use o atalho Crtl+C.";
				this.snackbar.status = true;
			}
		},
		contratoValida(contrato) {
			this.arquivos = [];
			this.dadosContrato = { existeContrato: false };
			return axios
				.post(`${this.backendUrl}contrato/valida`, {
					idcontrato: parseInt(contrato.idcontrato),
                    tipocontrato: 'A',
				})
				.then((res) => {
					if (res.data) {
						this.dadosContrato = contrato;
						this.dadosContrato.existeContrato = true;
					} else {
						this.dadosContrato = { existeContrato: false };
					}
					this.dialog = true;
				})
				.catch(() => {
					this.dadosContrato = { existeContrato: false };
					this.dialog = true;
				});
		},
		adicionarArquivo(arquivos) {
			let obj = {};
			obj.tipo =
				arquivos.name.split(".")[arquivos.name.split(".").length - 1];
			let reader = new FileReader();
			reader.readAsDataURL(arquivos);
			reader.onload = function () {
				obj.arquivo = reader.result.split("base64,")[1];
			};
			this.arquivos.push(obj);
			/*  arquivos.forEach((arquivo) => {
                let obj = {};
                obj.tipo = arquivo.type.split("/")[1];
                let reader = new FileReader();
                reader.readAsDataURL(arquivo);
                reader.onload = function () {
                    obj.arquivo = reader.result.split("base64,")[1];
                };
                this.arquivos.push(obj);
            }); */
		},
		enviarContrato() {
			this.carregando = true;
			this.dialog = false;
			return axios
				.post(`${this.backendUrl}contrato/envia`, {
					idcontrato: parseInt(this.dadosContrato.idcontrato),
					tipo: this.arquivos[0].tipo,
					arquivo: this.arquivos[0].arquivo,
                    tipocontrato: 'A',
				})
				.then((res) => {
					if (res.data) {
						this.$router.push(
							`/contrato/get/${this.dadosContrato.idcontrato}`
						);
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg =
								"Não foi possível realizar o cadastro do contrato!";
						} else {
							this.dialogErro.msg = res.data.erro;
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.dialogErro.title = "Erro";
					if (e.response.data.erro) {
						this.dialogErro.msg = e.response.data.erro;
					} else {
						this.dialogErro.msg =
							"Não foi possível realizar o cadastro do contrato!";
					}
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		dialogLiberarEncomenda(contrato) {
			this.dadosContrato = contrato;
			this.dialogEncomenda = true;
		},
		contratoAutorizar() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}contrato/autorizar`, {
					idcontrato: parseInt(this.dadosContrato.idcontrato),
				})
				.then((res) => {
					this.dialogEncomenda = false;
					if (res.data) {
						this.$router.push(
							`/contrato/get/${this.dadosContrato.idcontrato}`
						);
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg =
								"Não foi possível liberar o pedido!";
						} else {
							this.dialogErro.msg = res.data.erro;
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.dialogEncomenda = false;
					this.dialogErro.title = "Erro";
					if (e.response.data.erro) {
						this.dialogErro.msg = e.response.data.erro;
					} else {
						this.dialogErro.msg =
							"Não foi possível liberar o pedido!";
					}
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		async init() {
			this.busca.idfilial = this.usuario.idfilial;
			const json = localStorage.getItem("filtros_contratos");
			const filtro = JSON.parse(json);
			if (filtro) {
				this.busca = filtro;
			}
			await this.listarStatus();
			await this.listar(0);
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.title {
	height: 64px;
}
</style>